.cart-item-img {
    position: relative;
    width: 96px;
    height: 96px;
}

.cart-item-img button {
    position: absolute;
    top: 8px;
    left: 8px;
    height: 20px;
    width: 20px;
    background-color: #bdbcbc;
    color: #000000;
    opacity: .7;
    line-height: 16px;
    border: 1px solid #bdbcbc;
    border-radius: 50%;
}

.cart-item-img button:hover {
    opacity: 1;
    color: #FF0000;
}

.cart-item-title .item-unit {
    font-size: .8rem;
    font-style: italic;
    color: #6C757D;
    display: block;
}

.cart-item-title .main-title {
    font-weight: 600;
}

.cart-item-title .sub-title {
    display: block;
    font-size: .8rem;
}



@media (max-width: 576px) {
    .featured_cart .featured_cart_product {
        padding: 0;
    }

    .cart-item-img {
        width: 72px;
        height: 72px;
    }

    .cart-check-out {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        left: 0;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        z-index: 999;
    }
}