body {
    background-color: rgb(248, 249, 250) !important;
}

.button-xemthem {
    background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
    border: 0;
    border-radius: 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.5;
    outline: transparent;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    padding: 0 40px;
    white-space: nowrap;
}

.button-xemthem:not([disabled]):focus {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.button-xemthem:not([disabled]):hover {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

/* Danh mục */
.category {
    /* margin-top: 10px; */
    padding: 15px;
    font-size: 1.25rem;
    background-color: #0b42a9;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.category .show_category {
    background: #fff;
}

.category .show_category .imgCate img {
    height: 500px !important;
    width: 500px !important;

}

.category_detail {
    margin-top: -10px !important;
    /* padding: 15px; */

    background-color: #7cc1f6;
    border-radius: 10px;
    color: #212529;
    font-weight: 700;
}

.category_detail_item {
    /* padding: 5px; */
    margin-top: -15px !important;
    border-radius: 10px !important;
}

.category_banner img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
}

.cost {
    color: red;
}

.limited-text {
    display: -webkit-box;
    max-height: 4rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #212529;
}

.limited-text-3 {
    display: -webkit-box;
    max-height: 6rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #212529;
}

.category_detail_item_ .limited-text {
    font-weight: 600;
    font-size: 1.1rem;
}

.cate .bg-white {
    border-radius: 10px;
}


.carou {
    width: 100%;
    aspect-ratio: 3 / 1;
}

.carou img {
    width: 100%;
    height: 100%;
}

.account_home .dropdown-toggle.nav-link .icon_account_gray {
    margin: auto;
}

.category_detail_item_ {
    border: 1px solid #d2d2d2;
    margin: 5px !important;
    border-radius: 10px;
    height: 150px;
}

.img_cate {
    height: 120px !important;
    aspect-ratio: 1;
    object-fit: fill;
    transition: all ease .3s;
}

.img_cate:hover {
    transform: scale(1.15);
}


#sb-nav-fixed_ .page {
    display: flex;
    height: calc(100vh - 64px);
    flex-direction: column;
    justify-content: space-between;
}

#header .company {
    height: 100%;
}

#header .company .companyinfo .navbar_logo {
    width: 30%;
}

#header .company .companyinfo {
    color: #FFFFFF;
    font-size: 1.75rem;
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    height: 100%;
    align-items: center;
}

#header .menu .bnt_search {
    width: 64px;
    border-radius: 20px !important;
    margin: 2px !important;
}

.search-input .noboder {
    border: none !important;
    margin-left: 14px;
    padding-left: 0;
    height: 100%;
}

#header .menu .gio_hang {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

#header .menu .cart_number {
    left: 75% !important;
}

#header .menu .gio_hang .icon_nofi_gray {
    margin: auto;
}

.home-posts img {
    width: 100%;
    aspect-ratio: 3 / 2;
    object-fit: cover;
}

.home-posts .card {
    height: 22rem;
}

.top-head_ {
    position: fixed;
    z-index: 1039;
    margin-top: 0px !important;
    top: 0;
    right: 0;
    left: 0;
    height: 64px;
}

#sb-nav-fixed_ main.page {
    margin-top: 64px;
}

@media screen and (min-width: 1640px) {
    .categorory_dropdown {
        width: 50% !important;
        margin-left: -10rem !important;
    }
}

@media screen and (max-width: 991px) {
    .category_detail_item_ {
        height: 120px;
    }

    .home-posts .card {
        height: 19rem;
    }
}

@media screen and (max-width: 767px) {
    .category_detail_item {
        /* padding: 5px; */
        margin-top: -15px !important;
        border-radius: 10px !important;
        height: 280px !important;
    }

    .categorory_dropdown {
        z-index: 1040 !important;
        width: 80% !important;
        margin-left: -9rem !important;
        margin-top: 52px !important;
    }

    .category_banner {
        display: flex;
        justify-content: space-evenly;
    }



    .overflow_cate .col-md-1 {
        width: 25% !important;
    }

    .page {
        padding-top: 10px;

    }

    .title {
        margin-left: 10px !important;
        min-width: 80px !important;
        display: flex;
    }

    .img_cate {
        height: 120px !important;
        aspect-ratio: 1 / 1;
    }
}

@media screen and (max-width: 576px) {
    body {
        font-size: 12px !important;
    }

    .categorory_dropdown {
        z-index: 1040 !important;
        top: 0 !important;
        position: fixed !important;
        width: 100% !important;
        margin-left: -1rem !important;
    }

    .category_detail_item {
        height: fit-content !important;
    }

    .search-input {
        width: 100% !important;
    }

    .footer_tex {
        display: flex;
        align-items: center;
        justify-content: center !important;
        padding-top: 4px;
        padding-bottom: 0px;
    }

    #header .company {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .row-header {
        min-width: 100%;
    }

    .gio_hang .title,
    .account_home .title {
        display: none;
    }

    .account_home .dropdown {
        margin: auto 0;
    }

    .account_home .dropdown-toggle.nav-link::after {
        position: absolute;
        bottom: 0;
        right: -5px;
    }

    a {
        padding: 0.5rem 0rem;
        vertical-align: middle;
    }

    .top-head_ .menu {
        height: 64px;
    }

    .top-head_ .menu .row-item-center,
    .top-head_ .menu .row-item-right {
        height: 100%;
    }

    #header .menu .cart_number {
        left: 100% !important;
        top: 25%;
    }

    .home-posts .row {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
    }
}


footer .search-input {
    width: 300px;
}

.search-input {
    border-radius: 20px;
    background-color: #fff;
    height: 40px;
}



.nh {
    display: flex !important;
    margin-right: 0rem !important;
}

/* .nh:hover{
    .danhmuc{
        display: block;
    }
} */



.menu .nh .danhmuc {
    display: hidden;
}

.bnt_search_footer {
    border-radius: 20px !important;
    margin: 3px !important;
    padding: 0 16px;
    height: calc(100% - 6px);
    background: #f7941e;
    border: 1px solid #f7941e;
    font-size: 1rem;
    color: #fff;
}

.icon-footer {
    padding: 5px;
    font-size: 25px
}

.footer_home ul,
.footer_home li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    color: #212529;
    transition: all ease .3s;
}

.footer_home a {
    color: #212529;
}

.footer_home a:hover {
    color: #0869b2;
}

.footer_home.footer_home ul,
.footer_home li:hover,
.footer_home li:hover a {
    list-style-type: disclosure-closed;
    color: #0869b2;
    padding-left: 6px;
}

.footer_title {
    text-transform: uppercase;
    color: #0869b2;
    font-weight: 700px;
}

.footer_title_name {
    text-transform: uppercase;
    color: #0869b2;
    font-weight: 700px;
    display: flex;
    align-items: center;
}

.overflow_cate {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto
}

.overflow_cate .col-md-1 {
    width: 10%;
    padding: 0px 5px;
}

.companyinfo:hover {
    color: #F2F2F2;
}

.footer_tex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}



.form-control:focus {
    box-shadow: none !important;
}

.categorory_dropdown {
    z-index: 1040 !important;
    top: 0;
    position: fixed;
    width: 50%;
    margin-top: 53px;
    padding: 10px;
    margin-left: -100px;
}

.categorory_dropdown .child {
    position: fixed;
    /* max-height: 300px; */
    /* overflow: scroll; */
    align-items: flex-start;
    margin-top: -50px;
}

.categorory_dropdown .scroll_ {
    overflow: auto;
    max-height: 450px;
    padding-top: 0px !important;

}

.nh {
    cursor: pointer;
}

.text_cate {
    font-weight: 700;
    color: #000;
}

.text_cate:hover {
    /* background-color: #a3b18a; */
}

.cate_text:hover {
    background-color: #a3b18a;
}

.cate_child:hover {
    background-color: #adc178;
    color: #000;
}



.account_home {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.category_product_mb .category_detail_item_ {
    flex-direction: column;
    height: 252px;
}

.category_product_mb {
    display: flex;
    flex-wrap: nowrap !important;
    flex-direction: row;
    overflow: auto;
}