.featured__item {
    border: 1px solid #f2f2f2;
}

.featured__product .row>col {
    padding-block: 0;
}

.featured__item .table> :not(caption)>*>* {
    border-bottom-width: 0;
}

.featured__item__pic {
    height: 284px;
    background: url("../../../../public/assets/images/picture.png") no-repeat;
    background-position: center;
    background-size: 100px;
}

.featured__item__pic img {
    object-fit: contain;
    height: -webkit-fill-available;
    transition: transform .3s;
}

.featured__item__pic img:hover {
    transform: scale(1.05);
}

.featured__item__text {
    height: 150px;
    padding-top: 1rem;
}

.featured__item__text .product__item__title {
    display: block;
    display: -webkit-box;
    line-height: 1.1rem;
    height: 2.33rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    visibility: visible;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    font-size: 1.1rem;
    color: #212529;
    font-weight: 600;
}

.featured__item__text a.product__item__title:hover {
    color: #0D6EFD;
}

.product__item__price {
    overflow-y: auto;
    height: 180px;
}

.product__item__price .item-price {
    display: flex;
    flex-direction: column;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #f2f2f2;
}

.product__item__price .item-price:last-child {
    border-bottom: none;
}

.product__item__price .item-price .item-btn {
    width: 80%;
    margin: auto;
    margin-top: .2rem;
}

.product__item__price .item-price .item-btn button {
    width: 100%;
    margin: auto;
}

.product__item__price .item-price .item-qty button {
    width: 3rem;
}

.product__item__btn {
    text-align: center;
    margin-top: .5rem;
}

.product__item__btn .btn-outline-success {
    width: 50%;
}

#sidebarToggle {
    position: absolute;
    left: 242px;
}

.sb-nav-fixed #home_filter.position-fixed {
    top: 55px;
    width: calc(100% - 242px);
    background: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 1px 0px;
    z-index: 1036;
}

.sb-nav-fixed.sb-sidenav-toggled #home_filter.position-fixed {
    width: calc(100%);
}

@media (max-width: 991px) {
    #sidebarToggle {
        left: unset;
        right: 1rem;
    }

    .sb-nav-fixed #home_filter.position-fixed {
        width: calc(100%);
    }


    .sb-topnav ul.navbar-nav {
        position: absolute;
        right: 3.5rem;
    }
}

@media (max-width: 768px) {
    .featured__item {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 1px 0px;
    }
}

@media (max-width: 575px) {
    .featured__item__text .product__item__title {
        height: 2.7rem;
    }

}