.text-titleVi {
    color: #000;
}

.text_descVi {
    font-style: italic;
    color: #626262;
}

.post_img {
    height: 250px;
    object-fit: contain;
}

h2.blog-topic-name {
    border-bottom: 1px solid #d2d2d2;
}

.blog-desc {
    font-style: italic;
    color: #626262;
}

.text_PostsRelated {
    padding: 15px;
    font-size: 1.25rem;
    background-color: #0b42a9;
    border-radius: 10px;
    color: #fff;
    font-weight: 700;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

#page_blog {
    .row {
        h2.blog-topic-name {
            border-bottom: 1px solid #d2d2d2;
        }

        .card {
            height: 20rem;
            background-color: #fff;

            .card-body {
                position: absolute;
                width: 100%;
                display: flex;
                justify-content: space-around;
                flex-direction: column;

                .card-title {
                    margin-bottom: 0;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .card-title:hover {
                    color: #0C63E4;
                }

                .card-text {
                    margin-bottom: 0;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .cart-reviews {
                    height: 2rem;
                    line-height: 2rem;
                }
            }
        }

        .col-md-3 .card {

            img {
                height: 50%;
                object-fit: contain;
                padding: 0.5rem 0;
            }

            .card-body {
                background-color: #fff;
                top: 50%;
                height: 50%;

                .card-title {
                    -webkit-line-clamp: 2;

                    a {
                        color: inherit;
                    }
                }

                .card-text {
                    -webkit-line-clamp: 2;
                }

                .cart-reviews {
                    svg {
                        color: #212529;
                    }
                }
            }
        }

        .col-md-6 .card {

            img {
                height: 100%;
                object-fit: contain;
            }

            .card-body {
                color: #fff;
                background-image: linear-gradient(rgba(245, 245, 245, 0.1), rgba(0, 0, 0, 0.8));
                top: 40%;
                height: 60%;
                padding-top: 8%;

                .card-title {
                    -webkit-line-clamp: 1;

                    a {
                        color: inherit;
                    }
                }



                .card-text {
                    -webkit-line-clamp: 3;
                    color: #fff;
                }

                .cart-reviews {
                    svg {
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    #page_blog {
        .row {
            .card {
                margin-bottom: 0;
            }

            .col-md-3 .card {
                height: 11rem !important;
                padding: 0;

                img {
                    height: 10rem !important;
                    margin-left: 73%;
                    object-fit: contain;
                    z-index: 1;
                    width: 25%;
                }


                .card-body {
                    background-color: #fff;
                    // top: 50%;
                    top: 0 !important;
                    height: 10rem !important;
                    padding-right: 30%;

                    .card-title {
                        -webkit-line-clamp: 2;

                        a {
                            color: inherit;
                        }
                    }

                    .card-text {
                        -webkit-line-clamp: 2;
                    }

                    .text_descVi {
                        font-style: italic;
                        display: -webkit-box;
                        max-height: 2rem;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                    }

                    .cart-reviews {
                        svg {
                            color: #212529;
                        }
                    }
                }
            }

        }


    }

}