.featured_product_img .img_main {
    height: 32rem;
    border: none;
    /* padding: .5rem; */
}

.featured_product_img .img_main img {
    /* height: 23rem; */
    object-fit: contain;
}

.featured_product_img .image-gallery-left-nav .image-gallery-svg,
.featured_product_img .image-gallery-right-nav .image-gallery-svg {
    height: 60px;
    width: 60px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-inner {
    height: 6rem;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: inherit;
}

.image-gallery-slides {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
}

.image-gallery-slides .image-gallery-slide {
    height: 24rem;
}

.image-gallery-content.bottom.fullscreen .image-gallery-slides .image-gallery-slide {
    height: unset
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    height: inherit;
}

.featured_product_info .title {
    font-size: 1.5rem;
}

.featured_product_info .product_desc {
    display: block;
    display: -webkit-box;
    line-height: 16px;
    max-height: 4.6rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    visibility: visible;
    text-overflow: ellipsis;
    overflow: hidden;
}

.product__details__tab .product__details__tab__desc {
    max-height: 20rem;
    overflow: hidden;
    transition: all ease .5s;
    padding-bottom: 0;
}

.product__details__tab .product__details__tab__desc.show {
    max-height: none;
    padding-bottom: 3rem;
    transition: all ease .5s;
}


.product__details__tab .bg-article {
    background: linear-gradient(to bottom, rgba(255 255 255/0), rgba(255 255 255/62.5), rgba(255 255 255/1));
    bottom: 0;
    height: 105px;
    left: 0;
    position: absolute;
    margin-bottom: 0 !important;
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 42px;
    justify-content: center;
    transition: all ease .3s;
}

.product__details__rating {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.product__details__rating .fa-solid {
    color: #EDBB0E;
}